<template>
  <div>
    <div class="card text-center">
      <div v-for="(link, idx) in shuffledLinks" :key="idx" style="margin: 10px;margin-top: -10px">
        <router-link :to="{path: `/article/${link.slug}`}" v-if="idx < 3">
          <div v-if="size === 'sm'">
            <img
              v-lazy="link.metadata.cover.imgix_url+'?w=237&h=180&fit=fillmax&fill=blur'" style="border-radius: 0"
              width="257px"
              height="180px">
            <div
              style="padding-left:20px;padding-top: 10px;margin-top: -15px;background-color: rgba(0,0,0,0.6);border-radius: 0"
              class="text-left card btn-group-vertical">
              <h3 class="card-title">
                {{ link.metadata.text }}</h3>
              <p style="margin-top: -13px;font-size: 0.6rem;text-transform: uppercase">{{ link.metadata.caption }}</p>
            </div>
          </div>

          <div v-if="size !== 'sm'" class="text-center">
            <img
              v-lazy="link.metadata.cover.imgix_url+'?w=450&h=450&fit=clamp'" style="border-radius: 0" width="450px"
              height="450px">
            <div
              style="padding-left:20px;margin-top: -60px;height:90px;background-color: rgba(0,0,0,0.6);border-radius: 0"
              class=" card text-left">
              <p style="font-weight: bold;font-size: 1.2rem;line-height: 0.8;text-transform: capitalize">
                {{ link.metadata.text }}</p>
              <p style="margin-top: -8px;font-size: 0.8rem">{{ link.metadata.caption }}</p>
            </div>
          </div>
        </router-link>
        <MiniCard
          v-if="idx >= 3"
          :link="`/article/${link.slug}`"
          :title="link.metadata.text"
          :image="link.metadata.cover.imgix_url"
          :tags="hashTags(link)"
          :text="link.metadata.caption"
        ></MiniCard>
      </div>
    </div>
  </div>
</template>
<script>
import MiniCard from '@/components/Cards/MiniCard';

export default {
  name: 'DynamicArticles',
  components: { MiniCard },
  computed: {
    shuffledLinks: {
      get() {
        return (
          this.$store.state.articles.map(value => ({ value, sort: Math.random() }))
            .sort((a, b) => a.sort - b.sort)
            .map(({ value }) => value)
        );
      }
    }
    ,
    articles: {
      get() {
        return this.$store.state.articles;
      }
    }
  },
  created: function() {
    this.$store.dispatch('getArticles', { start: 1, limit: 10 });
  },
  methods: {
    hashTagsWithLink(link) {
      return link.slug
        .split('-')
        .map((x) => `<a class='btn-link' href='#${x}'>#${x}</a>`)
        .join('');
    },
    hashTags(link) {
      return link.slug
        .split('-')
        .map((x) => `#${x}`)
        .join(' | ');
    }
  },
  data: () => {
    return {
      youTube:
        'https://imgix.cosmicjs.com/33d0d570-eaad-11ee-b074-b5c8fe3ef189-YouTube.jpg',
      soundCloud:
        'https://imgix.cosmicjs.com/9c356f90-eab2-11ee-b074-b5c8fe3ef189-Danleech-Simple-Soundcloud.1024.png',
      spotify:
        'https://imgix.cosmicjs.com/dab2a390-eab3-11ee-b074-b5c8fe3ef189-spotify.jpg'
    };
  },
  props: {
    links: {},
    size: {
      default: 'sm',
      type: String
    }
  }
};
</script>
